import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Hero } from "../components/hero"

export const query = graphql`
  query NotFoundPageQuery {
    file(name: { eq: "bostonian-electric-electrician-working" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
const NotFoundPage = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="404: Not found" />
      <Hero
        image={banner}
        header="404: Not found"
        alt="Electrician working on wires"
      />
      <section className="container my-6 mx-auto px-4 font-sans">
        <h2>You just hit a page that doesn't exist...</h2>
        <p>Please use the Navigation to return home.</p>
      </section>
    </Layout>
  )
}
export default NotFoundPage
